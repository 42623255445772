import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Using the sFTP Service with your WQHD Camera",
  "path": "/Frequently_Asked_Question/Using_the_sFTP_Service_with_your_WQHD_Camera/",
  "dateChanged": "2022-05-26",
  "author": "Mike Polinowski",
  "excerpt": "My web space provider Strato recently deactivated the FTP service that I was using for my alarm recording. Can I still upload my videos using the INSTAR WQHD camera series?",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - Using the sFTP Service with your WQHD Camera' dateChanged='2022-05-23' author='Mike Polinowski' tag='INSTAR IP Camera' description='My web space provider Strato recently deactivated the FTP service that I was using for my alarm recording. Can I still upload my videos using the INSTAR WQHD camera series?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Using_the_sFTP_Service_with_your_WQHD_Camera/' locationFR='/fr/Frequently_Asked_Question/Using_the_sFTP_Service_with_your_WQHD_Camera/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "using-the-sftp-service-with-your-wqhd-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#using-the-sftp-service-with-your-wqhd-camera",
        "aria-label": "using the sftp service with your wqhd camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using the sFTP Service with your WQHD Camera`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: My web space provider Strato recently deactivated the FTP service that I was using for my alarm recording. Can I still upload my videos using the INSTAR WQHD camera series?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Our Full HD cameras only support `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`FTP`}</code>{` and the TLS encrypted version `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`FTPS`}</code>{`. With our new WQHD 2k+ line of cameras we are now also supporting FTP connections through SSH tunnels - also know as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sFTP`}</code>{`. For your Strato account you will just have to activate sFTP in the ecryption drop down menu and add your Strato account information.`}</p>
    <h2 {...{
      "id": "adding-a-camera-user",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-a-camera-user",
        "aria-label": "adding a camera user permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding a Camera User`}</h2>
    <p>{`If you want to use the sFTP service on a generic Linux server you first have to create a user for your camera, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ipcamera`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useradd`}</span>{` -m ipcamera`}</code></pre></div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-m`}</code>{` flag in this command will create a home directory for your user in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/home/ipcamera`}</code>{` that we can use for our camera uploads.`}</p>
    <p>{`It is also possible to define a specific directory as the home directory for this user, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/opt/INSTAR`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useradd`}</span>{` -m -d /opt/INSTAR ipcamera`}</code></pre></div>
    <p>{`To enable your camera to log in to your server using this userr, you need to set the user password. Use the following command and type and retype a password for the ipcamera user:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`passwd`}</span>{` ipcamera`}</code></pre></div>
    <h2 {...{
      "id": "creating-ssh-keys",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#creating-ssh-keys",
        "aria-label": "creating ssh keys permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating SSH Keys`}</h2>
    <h3 {...{
      "id": "on-your-linux-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#on-your-linux-server",
        "aria-label": "on your linux server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`On your Linux Server`}</h3>
    <p>{`Login to your server using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ipcamera`}</code>{` user and run the ssh key generator:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`ssh-keygen -t ed25519 -C `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'sFTP_server'`}</span>{`

Generating public/private ed25519 key pair.
Enter `}<span parentName="code" {...{
            "className": "token function"
          }}>{`file`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`which`}</span>{` to save the key `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`/home/ipcamera/.ssh/id_ed25519`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`:  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#leave empty`}</span>{`
Created directory `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'/home/ipcamera/.ssh'`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span>{`
Enter passphrase `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`empty `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` no passphrase`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`:  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#leave empty`}</span>{`
Enter same passphrase again:  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#leave empty`}</span>{`
Your identification has been saved `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` /home/ipcamera/.ssh/id_ed25519
Your public key has been saved `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` /home/ipcamera/.ssh/id_ed25519.pub
The key fingerprint is:
SHA256:ZevHZSnTAogNMrtSBCIF6fXJAXZXpALT0xYirUjGGyA sFTP_server`}</code></pre></div>
    <h3 {...{
      "id": "on-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#on-your-camera",
        "aria-label": "on your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`On your Camera`}</h3>
    <p>{`In the FTP Menu, once you set the `}<strong parentName="p">{`Encryption`}</strong>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sFTP`}</code>{`, you will see a button that will show you the SSH public key of your camera. When clicking it for the first time, your camera will generate those keys for you which might take a while:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3c63f234ed867d4521a672914473d798/60b3a/sFTP_for_yourWQHD_Camera_00a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABwElEQVQoz22Qy27TQBSG/QAodbKIHSl13HjseEyWWUFBSTxOmmYSO7479sxUQii9cVFBQqAuiIQqNux4BLpCvCOyJ6JB8OnXnNl8+o+O0O/3H1eYEDqbb/OPP2fv752be7QX5+aHd/tr8vLr4aEyHA4Hg0Gr1VJVVeAmrFjSt9nru+hyG178nfPP6zd303gjyzKEEACgKIqmafuyeXripNEq8HDo4XBVvWUWgTtPAnf0/FgUD2RZ1jRN13XDMErZsqxSNSHGC0JZUZCCkIJSyhihjFJaFISdndkIiaLYbDYBAP/IEGKMGWOkKAghpOBUM88ZYwihRqMhSRIAwKgQrIqq2SzlsqeSd/ruxxizbbter/+Re73e/tomb+YGpZT7hJA8zykh/5f5wUwTLjDO11mWJkmaRJw4jqI4jZM8yxB6kHVdBwA8rA0hPJmdzr1o7gY7fD9wse9iL4mDfD2y7YNajR+s2+0qiiLwWgtCw4TEn31/528vvCiKwsAPM5JcbdPz2w/L1RcvcIfjR7WaJEmdTkdV1Xa7LfBaLi8nzz6x8XUydhzHQbYzW0yz62m8eTGyX43Q5MlTRVWP1JKjit8TO6kR/abwagAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3c63f234ed867d4521a672914473d798/e4706/sFTP_for_yourWQHD_Camera_00a.avif 230w", "/en/static/3c63f234ed867d4521a672914473d798/d1af7/sFTP_for_yourWQHD_Camera_00a.avif 460w", "/en/static/3c63f234ed867d4521a672914473d798/7f308/sFTP_for_yourWQHD_Camera_00a.avif 920w", "/en/static/3c63f234ed867d4521a672914473d798/021aa/sFTP_for_yourWQHD_Camera_00a.avif 1179w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3c63f234ed867d4521a672914473d798/a0b58/sFTP_for_yourWQHD_Camera_00a.webp 230w", "/en/static/3c63f234ed867d4521a672914473d798/bc10c/sFTP_for_yourWQHD_Camera_00a.webp 460w", "/en/static/3c63f234ed867d4521a672914473d798/966d8/sFTP_for_yourWQHD_Camera_00a.webp 920w", "/en/static/3c63f234ed867d4521a672914473d798/58f5f/sFTP_for_yourWQHD_Camera_00a.webp 1179w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3c63f234ed867d4521a672914473d798/81c8e/sFTP_for_yourWQHD_Camera_00a.png 230w", "/en/static/3c63f234ed867d4521a672914473d798/08a84/sFTP_for_yourWQHD_Camera_00a.png 460w", "/en/static/3c63f234ed867d4521a672914473d798/c0255/sFTP_for_yourWQHD_Camera_00a.png 920w", "/en/static/3c63f234ed867d4521a672914473d798/60b3a/sFTP_for_yourWQHD_Camera_00a.png 1179w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3c63f234ed867d4521a672914473d798/c0255/sFTP_for_yourWQHD_Camera_00a.png",
              "alt": "Using the sFTP Service with your WQHD Camera",
              "title": "Using the sFTP Service with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back on your Linux server you can now check the directory the previous command generated the SSH keys in:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span>{` -la /home/ipcamera/.ssh

total `}<span parentName="code" {...{
            "className": "token number"
          }}>{`24`}</span>{`
-rw------- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` ipcamera ipcamera  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`399`}</span>{` Jun `}<span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`19`}</span>{`:33 id_ed25519
-rw-r--r-- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` ipcamera ipcamera   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`93`}</span>{` Jun `}<span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`19`}</span>{`:33 id_ed25519.pub
-rw-r--r-- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` ipcamera ipcamera  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`738`}</span>{` Jun `}<span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`19`}</span>{`:47 known_hosts`}</code></pre></div>
    <p>{`Here we can append the camera public key, as displayed in the webUI, to the file `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`known_hosts`}</code>{` (just create it if your server does not have one):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` /home/ipcamera/.ssh/known_hosts`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7ec7e059f2937d5444bc4ef1b50eeb9f/f53a0/sFTP_for_yourWQHD_Camera_00b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAkUlEQVQI1z3JWwrCMBCF4e7D6YMEbfGlJJmZXC1BpRdrbSD734tQRfj4OXAqF29kexuS8UlzRNuTvSqO5JOJN/aJXJIUOu06ZXdOYlAUFMeqLbkp26Xkc36J9fmzTGIcxDyIaRDTKObxf53eS5PXpmzt/VGB80AGkEETINd7QVMtsVb0BYpAM6DZB4HEA/FR8wdOmCYmSzMl4wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7ec7e059f2937d5444bc4ef1b50eeb9f/e4706/sFTP_for_yourWQHD_Camera_00b.avif 230w", "/en/static/7ec7e059f2937d5444bc4ef1b50eeb9f/d1af7/sFTP_for_yourWQHD_Camera_00b.avif 460w", "/en/static/7ec7e059f2937d5444bc4ef1b50eeb9f/7f308/sFTP_for_yourWQHD_Camera_00b.avif 920w", "/en/static/7ec7e059f2937d5444bc4ef1b50eeb9f/23a38/sFTP_for_yourWQHD_Camera_00b.avif 1062w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7ec7e059f2937d5444bc4ef1b50eeb9f/a0b58/sFTP_for_yourWQHD_Camera_00b.webp 230w", "/en/static/7ec7e059f2937d5444bc4ef1b50eeb9f/bc10c/sFTP_for_yourWQHD_Camera_00b.webp 460w", "/en/static/7ec7e059f2937d5444bc4ef1b50eeb9f/966d8/sFTP_for_yourWQHD_Camera_00b.webp 920w", "/en/static/7ec7e059f2937d5444bc4ef1b50eeb9f/dac18/sFTP_for_yourWQHD_Camera_00b.webp 1062w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7ec7e059f2937d5444bc4ef1b50eeb9f/81c8e/sFTP_for_yourWQHD_Camera_00b.png 230w", "/en/static/7ec7e059f2937d5444bc4ef1b50eeb9f/08a84/sFTP_for_yourWQHD_Camera_00b.png 460w", "/en/static/7ec7e059f2937d5444bc4ef1b50eeb9f/c0255/sFTP_for_yourWQHD_Camera_00b.png 920w", "/en/static/7ec7e059f2937d5444bc4ef1b50eeb9f/f53a0/sFTP_for_yourWQHD_Camera_00b.png 1062w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7ec7e059f2937d5444bc4ef1b50eeb9f/c0255/sFTP_for_yourWQHD_Camera_00b.png",
              "alt": "Using the sFTP Service with your WQHD Camera",
              "title": "Using the sFTP Service with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To make sure our server uses this key whenever a connection to our camera is established we can configure SSH by adding the following lines:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` /etc/ssh/sshd_config `}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "conf"
    }}><pre parentName="div" {...{
        "className": "language-conf"
      }}><code parentName="pre" {...{
          "className": "language-conf"
        }}>{`HostKey /home/ipcamera/.ssh/id_ed25519`}</code></pre></div>
    <p>{`Save your changes and restart the SSH agent:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`service`}</span>{` sshd restart`}</code></pre></div>
    <h2 {...{
      "id": "configuring-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configuring-your-camera",
        "aria-label": "configuring your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring your Camera`}</h2>
    <p>{`For my local Linux server on IP `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.111`}</code>{` - e.g. a RaspberryPi - I can use the following configuration:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/208c409a530731d712e4bcccdf4dd96c/60b3a/sFTP_for_yourWQHD_Camera_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB1UlEQVQoz13Qy27TQBgF4DxApDrKLmqGsePM+BJcWwoiddw6dly7duLLjD12F0gFtVwWICEgUQAhwTOw4Q1ou+AZUZyQhh6d7afzzzQ0TVNVdTAYyLh/NHKzzzfz1V24vAkW254vboPFbfLlrvr+Rxraqozc6bTT6UAIG2odRVExRsbwKbtelK9WxfVyV/LiY/b8Q3b5vrj61MMD0D2UJAkAIAjCDisYS/qRxmhSkJjEIU0imkQkDu3xaDQ0nhiabR3DR+DggIMQiqKIENrHWNeNvGCsLPOioHm+qR8EE8exJ05wHgqCwHFr3O/311hRlD2sl2VZlSW9D4njJIoi3/fn8znP861Wi+d5VOchZoxVVXVPCfF933Xd05PTIAg2GEKIEMIYP8RFUexjSmmapkm6TpZlEEKO4zbLayzLslLn39nsoiopJdtlSs/8YGJPLNM887zdmxFCoij+92GGrieEzUgVp2R9MaFpMot81/XckeM4gd/tdpvNJoRQEAQAQGMjZVmWJKzpxo836e+vyfIlJUVVsbx6vSpXv95evvsZp98o43u9drsNAIAQbrGqqjWW1MHj2DOfzczQHZtjy7LGJ9PQCplne/noODLHkizz9Sxf5y/3150Uifgw/gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/208c409a530731d712e4bcccdf4dd96c/e4706/sFTP_for_yourWQHD_Camera_01.avif 230w", "/en/static/208c409a530731d712e4bcccdf4dd96c/d1af7/sFTP_for_yourWQHD_Camera_01.avif 460w", "/en/static/208c409a530731d712e4bcccdf4dd96c/7f308/sFTP_for_yourWQHD_Camera_01.avif 920w", "/en/static/208c409a530731d712e4bcccdf4dd96c/021aa/sFTP_for_yourWQHD_Camera_01.avif 1179w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/208c409a530731d712e4bcccdf4dd96c/a0b58/sFTP_for_yourWQHD_Camera_01.webp 230w", "/en/static/208c409a530731d712e4bcccdf4dd96c/bc10c/sFTP_for_yourWQHD_Camera_01.webp 460w", "/en/static/208c409a530731d712e4bcccdf4dd96c/966d8/sFTP_for_yourWQHD_Camera_01.webp 920w", "/en/static/208c409a530731d712e4bcccdf4dd96c/58f5f/sFTP_for_yourWQHD_Camera_01.webp 1179w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/208c409a530731d712e4bcccdf4dd96c/81c8e/sFTP_for_yourWQHD_Camera_01.png 230w", "/en/static/208c409a530731d712e4bcccdf4dd96c/08a84/sFTP_for_yourWQHD_Camera_01.png 460w", "/en/static/208c409a530731d712e4bcccdf4dd96c/c0255/sFTP_for_yourWQHD_Camera_01.png 920w", "/en/static/208c409a530731d712e4bcccdf4dd96c/60b3a/sFTP_for_yourWQHD_Camera_01.png 1179w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/208c409a530731d712e4bcccdf4dd96c/c0255/sFTP_for_yourWQHD_Camera_01.png",
              "alt": "Using the sFTP Service with your WQHD Camera",
              "title": "Using the sFTP Service with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The login is the user login we created above. And port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`22`}</code>{` is the default `}<strong parentName="p">{`SSH`}</strong>{` port on Linux - you can verify that your system is using the default by running the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`netstat`}</span>{` -tlnp `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`grep`}</span>{` sshd                                                                               
tcp        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`      `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.0`}</span>{`.0.0:22 LISTEN      `}<span parentName="code" {...{
            "className": "token number"
          }}>{`621`}</span>{`/sshd: /usr/sbin 
tcp6       `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`      `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{` :::22      LISTEN      `}<span parentName="code" {...{
            "className": "token number"
          }}>{`621`}</span>{`/sshd: /usr/sbin`}</code></pre></div>
    <p>{`The `}<strong parentName="p">{`Storage Path`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`./recordings`}</code>{` tells your camera to use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/home/ipcamera/recordings`}</code>{` directory for `}<strong parentName="p">{`sFTP`}</strong>{` uploads. By activating the `}<strong parentName="p">{`Host Verification`}</strong>{` option we now have to verify the public key from our server - Click OK if the key is a match. Your camera will then authorize your server key and try to upload the test file:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d8d3a8a78c9be94b16af95622ae5f87c/2e367/sFTP_for_yourWQHD_Camera_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7rAAAO6wFxzYGVAAACqElEQVQoz22S20uTYRzHvfRGr0zBw+ZpnrYEoYQgFcLFlKbv+7pDrpxrFZinLUnmKaEEo1I6YKNMnfq6zU2nr3QwJQIJbyK6C7zuH/nE+0guqYsPz8Xzez7P9/vwZNTW1mKxWDBbzlJlKqWxM4RtZJvm4CrNQ2s0DappAms0DqpcCqwiT+5wrq2PixfOU1ZuIjc3l4KCAjJqamrQMVsslBgN9Iamea19Y1b9zLPoF2bX0syonwVPV/Z5tXnI6PQrXA4FSZJRFIXW1ta0UE9pNBoZGxlm/6PGzmacd1sJ3m8nT9iMr5KMLhNXl0hEIzybfYLH48FutzM1NYXP5zstNBgMjI2PE4uvMzAYIHh3SKx/6B8YpK9/gJ47vczPv+XBw4c0NTXR0NCIw+Ggrq6OjOrqasxms0AXTkxMEIlEkKR2XE4ninxcR5ZlJEkSq15tcXFRzFZVVWGz2bBareguIRRv+JdwZWUFu+JG6fTScbULh/saHW4PsrNTYJcUFhYWCAQCZGdnYzKZxHMVFRWlE/6pfJJQ6cDp9uB0uUVSgcuFy+1GkmUxowszMzPJz88X6Of/K1xeXqa1pYX2Njtt7RJK7yOk4Xn6nLd4Y7Vx/9JlIktLBINBsrKyRLri4mIqKipOV9Y3RkdH0TSNubk5wuHwMW8WCM9HePn8BeGZGZ4/foyqqiJhTk4O5eXlonZ9ff2/3yYUCqHt7LCeSLCxsXFMMslGMkEsFmUtHkONRUmlUkKYl5cnhCUlJVRWVp4WFhmMPJgc59f3DxwdJPj0bovUlsb2Vgpt7xDt608+7R7wYz3B0e4eI/fukXPmDGVlZaKyHuhEqKPfZLdfob/nFrdvdOHr9uL1dtPt9dLt8+P138bn83Pzehc9fr/4KoWFhZSWloqEOr8BW/P4M0Oau1cAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d8d3a8a78c9be94b16af95622ae5f87c/e4706/sFTP_for_yourWQHD_Camera_02.avif 230w", "/en/static/d8d3a8a78c9be94b16af95622ae5f87c/d1af7/sFTP_for_yourWQHD_Camera_02.avif 460w", "/en/static/d8d3a8a78c9be94b16af95622ae5f87c/7f308/sFTP_for_yourWQHD_Camera_02.avif 920w", "/en/static/d8d3a8a78c9be94b16af95622ae5f87c/730d0/sFTP_for_yourWQHD_Camera_02.avif 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d8d3a8a78c9be94b16af95622ae5f87c/a0b58/sFTP_for_yourWQHD_Camera_02.webp 230w", "/en/static/d8d3a8a78c9be94b16af95622ae5f87c/bc10c/sFTP_for_yourWQHD_Camera_02.webp 460w", "/en/static/d8d3a8a78c9be94b16af95622ae5f87c/966d8/sFTP_for_yourWQHD_Camera_02.webp 920w", "/en/static/d8d3a8a78c9be94b16af95622ae5f87c/fbb05/sFTP_for_yourWQHD_Camera_02.webp 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d8d3a8a78c9be94b16af95622ae5f87c/81c8e/sFTP_for_yourWQHD_Camera_02.png 230w", "/en/static/d8d3a8a78c9be94b16af95622ae5f87c/08a84/sFTP_for_yourWQHD_Camera_02.png 460w", "/en/static/d8d3a8a78c9be94b16af95622ae5f87c/c0255/sFTP_for_yourWQHD_Camera_02.png 920w", "/en/static/d8d3a8a78c9be94b16af95622ae5f87c/2e367/sFTP_for_yourWQHD_Camera_02.png 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d8d3a8a78c9be94b16af95622ae5f87c/c0255/sFTP_for_yourWQHD_Camera_02.png",
              "alt": "Using the sFTP Service with your WQHD Camera",
              "title": "Using the sFTP Service with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`cat`}</span>{` /home/ipcamera/.ssh/id_ed25519.pub
ssh-ed25519 AAAAC3NzaC1lZDI1NTE5AAAAICVmROPDTwmGE6Qfo2+PtvY/FTH9/izLg1TW/T8rLezX sFTP_server`}</code></pre></div>
    <p>{`We can verify that the upload was successful by checking the recordings directory. It should contain the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Test-Snapshot.jpeg`}</code>{` test file:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span>{` -la /home/ipcamera/recordings                                                                        
total `}<span parentName="code" {...{
            "className": "token number"
          }}>{`132`}</span>{`
drwxr-xr-x `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` ipcamera ipcamera   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Jun `}<span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span>{`:34 `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span>{`
drwxr-xr-x `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span>{` ipcamera ipcamera   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Jun `}<span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span>{`:34 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
-rw-r--r-- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` ipcamera ipcamera `}<span parentName="code" {...{
            "className": "token number"
          }}>{`124886`}</span>{` Jun `}<span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span>{`:46 Test-Snapshot.jpeg`}</code></pre></div>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      